export const STATUSES =  {
  ON_APPROVAL: 'ON_APPROVAL',  // На согласовании
  PKO_NOT_PASSED: 'PKO_NOT_PASSED',  // Не пройдено
  PKO_PASSED: 'PKO_PASSED',  // Пройдено
  PREPARE: 'PREPARE',  // Подготовка
  ON_REWORK: 'ON_REWORK',  // На доработке

  NO: 'NO',  // Отклонено
  REWORK: 'REWORK',  // Доработка
  YES: 'YES',  // Согласовано
  WAIT_FOR_DECISION: 'WAIT_FOR_DECISION',  // Ожидает решения

  NOT_PASSED: 'NOT_PASSED',
  PASSED: 'PASSED',
  ON_APPLICATION_APPROVING: 'ON_APPLICATION_APPROVING',
};

export default {
  STATUSES,
}
