<template>
  <div :class="$style.root">
    <VDataTable
        :headers="[
          { text: 'ID', value: 'id', sortable: false },
          { text: 'ИНН', value: 'inn', sortable: false },
          { text: 'Организация', value: 'name', sortable: false },
          { text: 'Статус', value: 'status.name', sortable: false },
          { text: 'Средняя профильная оценка ПР', value: 'mark.average', sortable: false},
          { text: 'Общая квалификационная оценка', value: 'mark.general', sortable: false},
          { text: 'Интегральная комплексная оценка ПР', value: 'mark.integral', sortable: false},
          { text: 'Комплексная оценка ИИ', value: 'mark.complexEngineer', sortable: false},
          { text: 'Экспертная оценка', value: 'mark.expert', sortable: false},
          ...Object.keys(markSections).map((code) => ({ text: markSections[code], value: `sections.${code}.value`, sortable: false})),
        ]"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
          itemsPerPageText: '',
        }"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"
        :item-class="rowClassControl"
        @update:page="page => $router.push({ query: { ...$route.query, page } })"
        @update:items-per-page="size => $router.push({ query: { ...$route.query, size } })"
    >
      <template v-slot:body.prepend>
        <tr v-if="isReviewer || isSpectator">
          <td v-for="key in ['id', 'inn', 'companyName']" :key="key">
            <VTextField
                dense
                clearable
                hide-details
                :value="$route.query[key]"
                @input="value => $router.push({ query: { ...$route.query, [key]: value } })"
            />
          </td>
          <td>
            <VSelect
                dense
                clearable
                hide-details
                :items="statuses"
                :value="$route.query['status']"
                @input="value => $router.push({ query: { ...$route.query, ['status']: value } })"
            />
          </td>
          <td :colspan="3 + Object.keys(markSections).length" />
        </tr>
      </template>
      <template v-slot:item.control="{ item: { id, comments } }">
        <slot name="control" v-bind="{ id, comments }" />
      </template>
    </VDataTable>
  </div>
</template>

<script>
import { forEach } from 'lodash-es';
import { mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES } from '@/store/pkopir/enums';
export default {
  name: 'RegistryList',
  props: {
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
  },
  data: function() {
    return {
      options: {
        page: this.page,
        itemsPerPage: this.size
      },
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    markSections() {
      const result = {};
      forEach(this.items, ({ sections }) => {
        forEach(sections, ({ name }, code) => {
          result[code] = name;
        });
      })
      return result;
    },
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkopir');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkopir');
    },
    statuses() {
      return [
        {text: 'Не пройдено', value: STATUSES.NOT_PASSED },
        {text: 'Пройдено', value: STATUSES.PASSED },
        {text: 'В стадии прохождения', value: STATUSES.ON_APPLICATION_APPROVING },
      ]
    }
  },
  methods: {
    rowClassControl({ status }) {
      if(STATUSES.NOT_PASSED === status.code) return 'red lighten-4';
      if(STATUSES.PASSED === status.code) return 'green lighten-4';
      if(STATUSES.ON_APPLICATION_APPROVING === status.code) return 'orange lighten-4';
      return '';
    }
  }
}
</script>

<style module lang="scss">
.root {
  th {
    font-size: 12px !important;
    &:nth-child(1) {
      width: 60px !important;
      max-width: 60px !important;
      min-width: 60px !important;
    }
    &:nth-child(2) {
      width: 60px !important;
      max-width: 60px !important;
      min-width: 60px !important;
    }
    &:nth-child(3) {
      width: 260px !important;
      max-width: 260px !important;
      min-width: 260px !important;
    }
    &:nth-child(4) {
      width: 180px !important;
      max-width: 180px !important;
      min-width: 180px !important;
    }
    &:nth-child(n + 5) {
      width: 140px !important;
      max-width: 140px !important;
      min-width: 140px !important;
    }
    &:nth-child(n + 8) {
      width: 200px !important;
      max-width: 200px !important;
      min-width: 200px !important;
    }
  }
  td {
    font-size: 12px !important;
  }
}
</style>
