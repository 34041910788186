var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.root},[_c('VDataTable',{attrs:{"headers":[
        { text: 'ID', value: 'id', sortable: false },
        { text: 'ИНН', value: 'inn', sortable: false },
        { text: 'Организация', value: 'name', sortable: false },
        { text: 'Статус', value: 'status.name', sortable: false },
        { text: 'Средняя профильная оценка ПР', value: 'mark.average', sortable: false},
        { text: 'Общая квалификационная оценка', value: 'mark.general', sortable: false},
        { text: 'Интегральная комплексная оценка ПР', value: 'mark.integral', sortable: false},
        { text: 'Комплексная оценка ИИ', value: 'mark.complexEngineer', sortable: false},
        { text: 'Экспертная оценка', value: 'mark.expert', sortable: false},
        ...Object.keys(_vm.markSections).map((code) => ({ text: _vm.markSections[code], value: `sections.${code}.value`, sortable: false})),
      ],"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
        itemsPerPageText: '',
      },"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.count,"mobile-breakpoint":0,"item-class":_vm.rowClassControl},on:{"update:options":function($event){_vm.options=$event},"update:page":page => _vm.$router.push({ query: { ..._vm.$route.query, page } }),"update:items-per-page":size => _vm.$router.push({ query: { ..._vm.$route.query, size } })},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [(_vm.isReviewer || _vm.isSpectator)?_c('tr',[_vm._l((['id', 'inn', 'companyName']),function(key){return _c('td',{key:key},[_c('VTextField',{attrs:{"dense":"","clearable":"","hide-details":"","value":_vm.$route.query[key]},on:{"input":value => _vm.$router.push({ query: { ..._vm.$route.query, [key]: value } })}})],1)}),_c('td',[_c('VSelect',{attrs:{"dense":"","clearable":"","hide-details":"","items":_vm.statuses,"value":_vm.$route.query['status']},on:{"input":value => _vm.$router.push({ query: { ..._vm.$route.query, ['status']: value } })}})],1),_c('td',{attrs:{"colspan":3 + Object.keys(_vm.markSections).length}})],2):_vm._e()]},proxy:true},{key:"item.control",fn:function({ item: { id, comments } }){return [_vm._t("control",null,null,{ id, comments })]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }