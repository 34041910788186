<template>
  <div :class="$style.root">
    <VBtn class="float-right mt-4" @click="onDownload" depressed small color="primary">Выгрузить в excel</VBtn>
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}}</h1>
    <div class="mb-4 clearfix">
      <VBtn depressed :to="{ name: 'pkopir/CounterpartyListView' }">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад к списку
      </VBtn>
    </div>
    <RegistryList
        :items="registries.items"
        :count="registries.count"
        :page="page"
        :size="size"
        :loading="loading"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import RegistryList from '@/components/pkopir/RegistryList/RegistryList';
export default {
  name: 'RegistryListView',
  components: {
    RegistryList,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      loading: 'pkopir/pending',
      registries: 'pkopir/registries',
    }),
  },
  methods: {
    ...mapActions({
      fetchRegistryList: 'pkopir/fetchRegistryList',
      downloadRegistryList: 'pkopir/downloadRegistryList',
    }),
    onDownload() {
      const { page, size, filter } = this;
      this.downloadRegistryList({ page, size, filter });
    },
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchRegistryList({ page, size, filter });
    }, 500),
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>

<style module lang="scss">
.root {}
</style>
